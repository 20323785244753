@tailwind base;
@tailwind components;
@tailwind utilities;

.box-h {
  margin: 100px auto;
  text-align: center;
}

.grid {
  margin: auto;
  max-width: 99vw;
}
.boxes {
  color: #fff;
  height: 300px;
  overflow: hidden;
}
.office-automation {
  padding: 50px;
  text-align: center;
  background-color: #11111184;
}
.Data-Centre-Solutions {
  padding: 50px;
  text-align: center;
  background-color: #11111184;
}
.boxes img {
  width: 100%;
  color: #fff;
}

@media screen and (max-width: 1045px) {
}

@media screen and (max-width: 700px) {
  .boxes {
    color: #fff;
    height: 200px;
    overflow: hidden;
  }
}

@media screen and (max-width: 420px) {
  .boxes {
    color: #fff;
    height: 150px;
    overflow: hidden;
  }
}

@media screen and (max-width: 330px) {
  .boxes {
    color: #fff;
    height: 100px;
    overflow: hidden;
  }
}

/* 
@media screen and (max-width: 591px) {
  .Data-Centre-Solutions {
    margin: 20px auto;
    transform: translateY(30%);
    height: 100%;
  }
}
@media screen and (max-width: 520px) {
  .Data-Centre-Solutions {
    margin: 20px auto;
    transform: translateY(30%);
    height: 400px;
  }
} */

/* 
@media screen and (max-width: 491px) {
  .Data-Centre-Solutions {
    margin: 20px auto;
    transform: translateY(30%);
    padding: 0 50px 30px;
  }
}
@media screen and (max-width: 480px) {
  .Data-Centre-Solutions {
    margin: 20px auto;
    transform: translateY(30%);
    padding: 0 50px 40px;
  }
}
@media screen and (max-width: 420px) {
  .Data-Centre-Solutions {
    margin: 20px auto;
    transform: translateY(30%);
    padding: 0 50px 30px;
  }
}

@media screen and (max-width: 390px) {
  .Data-Centre-Solutions {
    margin: 20px auto;
    transform: translateY(30%);
    padding: 0 50px 40px;
  }
}
@media screen and (max-width: 375px) {
  .Data-Centre-Solutions {
    margin: 20px auto;
    transform: translateY(30%);
    padding: 0 50px 200px;
  }
} */
