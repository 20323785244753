@tailwind base;
@tailwind components;
@tailwind utilities;

.text {
  padding: 2rem;
}

.text h1 {
  font-size: 5rem;
}

.text .border-0 {
  background-color: #149ddd;
  height: 0.5rem;
  width: 17%;
  margin-bottom: 2rem;
}

.text p {
  margin: 0px 0px 20px;
  font-size: 17px;
}
.some-text {
  display: block;
  margin: 10px 0px 40px;
  font-size: 16px;
}

.text-a {
  padding-top: 30px;
  flex-direction: column;
}

.text-a p {
  margin: 0px 0px 20px;
  font-size: 17px;
}

.text-0 {
  margin: 10px 0 40px;
}
