@tailwind base;
@tailwind components;
@tailwind utilities;

.active::after {
  position: absolute;
  content: "";
  width: 30%;
  height: 2px;
  background-color: #17a1cf;
  bottom: -4px;
  left: 20px;
}
.logo {
  width: 9rem;
}
nav {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  backdrop-filter: blur(20px);
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
  padding: 10px 80px;
  z-index: 100;
  width: 100%;
  top: 0;
}

#navbar {
  display: flex;
  align-items: center;
  justify-content: center;
}

#navbar li {
  list-style: none;
  padding: 0 20px;
  margin-bottom: -10px;
  position: relative;
}

#navbar li .link {
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 600;
  color: rgb(36, 49, 68);
  transition: 0.3s ease-in-out;
}

#navbar li .link:not(.active):hover:after {
  content: "";
  width: 30%;
  height: 2px;
  background-color: #17a1cf;
  position: absolute;
  bottom: -4px;
  left: 20px;
}

#mobile {
  display: none;
}

#mobile i {
  color: #14248e;
  margin: 21.3px 0;
}
@media screen and (max-width: 896px) {
  #mobile {
    display: block;
    cursor: pointer;
  }

  #navbar {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    top: 90px;
    right: -300px;
    width: 250px;
    height: 100vh;
    background-color: rgb(0, 7, 27);
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
    padding: 40px 0 0 10px;
    transition: 0.3s ease-in-out;
  }
  
  /* .backdrop {
    position: fixed;
    top: 0px;
    right: -300px;
    width: 550px;
    height: 100vh;
    background-color: rgb(0, 0, 0);
    box-shadow: 0 40px 60px rgb(0, 0, 0);
    backdrop-filter: blur(20px);
    padding: 40px 0 0 10px;
  } */

  #navbar li {
    margin-bottom: 25px;
  }

  #mobile i {
    position: absolute;
    font-size: 24px;
    cursor: pointer;
    margin-top: -20px;
    margin-left: 20px;
    background-color: #fff;
    border: 2px solid #14248e77;
    border-radius: 10%;
    padding: .5rem;
  }
  #navbar.active1 {
    right: 0px;
    top: 0;
  }
  #navbar li .link {
    color: rgb(255, 255, 255);
  }
}
