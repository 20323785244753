.logo-carousel {
}
.carousel-logo {
  /* background-color: white; */
}
.carousel {
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.logos-0 {
  overflow: hidden;
  padding: 60px 0;
  /* background: white; */
  white-space: nowrap;
  position: relative;
}

.logos-0:before,
.logos-0:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}

/* .logos-0:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), black);
} */

/* .logos-0:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), black);
} */

.logos-0:hover .logos-slide-0 {
  animation-play-state: paused;
}

.logos-slide-0 {
  display: inline-block;
  animation: 35s slide infinite linear;
}

.logos-slide-0 img {
  height: 50px;
  margin: 0 40px;
}