@tailwind base;
@tailwind components;
@tailwind utilities;

.services2 .holder1 {
  margin: 0 0 70px;
  width: 100%;
}

.services2 .holder1 .heading1 {
  position: relative;
  text-align: center;
  padding: 50px 40px 200px;
}

.services2 .holder1 .heading1 h2 {
  position: relative;
  font-size: 5rem;
  margin-bottom: 20px;
}
.services2 .holder1 .container1 {
  /* 
     display: grid;
     grid-template-columns: repeat(3, 1fr);
     gap: 1.5rem;
     margin: 0 50px;
     */
  width: 100%;
}
.services2 .holder1 .container1 .box-0,
.services2 .holder1 .container1 .box-1,
.services2 .holder1 .container1 .box-2 {
  /* position: relative;
     width: 100%;
     background-color: #ffffff;
     text-align: center;
     padding: 40px; */
  box-shadow: 0 4px 8px 0 #00000000, 0 7px 20px 0 rgba(0, 0, 0, 0.1);
}

.services2 .holder1 .container1 .box-0,
.services2 .holder1 .container1 .box-2 {
  border-radius: 2rem 10rem;
  margin-top: 1rem;
}

.services2 .holder1 .container1 .box-1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #011032b5; /* Adjust the color and opacity as needed */
  border-radius: 10rem 2rem;
  z-index: -2;
}

.services2 .holder1 .container1 .box-1 {
  background-image: url(image/31182906.jpg);
  background-size: 1190px;
  z-index: -3;
  color: #fff;
  border-radius: 10rem 2rem;
  margin-top: 1rem;
}

.services2 .holder1 .container1 .box-0 .h,
.services2 .holder1 .container1 .box-2 .h {
  color: #777;
}

.services2 .holder1 .container1 .box-1 .h {
  color: #fff;
}

.services2 .holder1 .container1 h3 {
  font-size: 2rem;
}

.services2 .holder1 .container1 h4 {
  font-size: 18px;
  margin: 20px;
}

.services2 .holder1 .container1 .p {
  font-size: 70px;
}

.services2 .holder1 .container1 .p1 {
  margin: 10px;
  padding: 40px;
  width: 300px;
  border-bottom: 1px solid #a8a8a8;
}
/* 
  .services2 .holder1 .container1 .benifits {
     text-align: left;
     margin: 30px 0 20px 0px;
  }
  */
.services2 .holder1 .container1 .benifits .svg p,
.services2 .holder1 .container1 .benifits .svg-1 p {
  margin: 10px 0;
}
/*
  .services2 .holder1 .container1 .get {
     height: 45px;
     width: 100%;
     background-color: #ef4323;
     border-radius: 40px;
  }
  
  .services2 .holder1 .container1 .box-0 .get {
     margin: 19px 0 0;
  } */

.services2 .holder1 .container1 .get p {
  /* padding: 15px; */
  color: #fff;
  text-align: center;
}

.services2 .holder1 .container1 .p2 {
  /* text-align: center;
     padding: 25px; */
  font-size: 15px;
}

.services2 .holder1 .container1 .benifits .svg,
.services2 .holder1 .container1 .benifits .svg-1 {
  display: flex;
}

.services2 .holder1 .container1 .benifits .svg svg,
.services2 .holder1 .container1 .benifits .svg-1 svg {
  height: 40px;
  color: #032d8e;
}

.services2 .holder1 .container1 .benifits .svg-1 svg {
  margin-left: 7px;
}
.services2 .holder1 .container1 .benifits .svg-1 p {
  margin-left: 7px;
}

@media screen and (max-width: 575px) {
  .services2 .holder1 .container1 .box-0,
  .services2 .holder1 .container1 .box-1,
  .services2 .holder1 .container1 .box-2 {
    width: 27rem;
  }
}
