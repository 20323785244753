@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  max-width: 100vw;
}
body {
  background-color: rgb(5, 7, 36);
  max-width: 100vw;
  overflow-x: hidden;
  color: #fff;
}
.app {
  font-family: sans-serif;
}

.whatsapp {
  position: fixed;
  bottom: 20px;
  right: -5px;
  z-index: 100;
}